<template>
  <div>
    <div id="pc">
      <div class="pc-top">
        <div style="line-height: 1.5rem">
          <img src="../../assets/pc新闻中心/022017595270_0web.png" alt="" />
        </div>
      </div>
      <!-- <menutable></menutable> -->
      <div class="breadmenu" style="display: flex;background-color: #111421;">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="background-color: #111421;">
          <el-breadcrumb-item style="font-size: 0.12rem;" :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 0.1rem;" :to="{ path: '/newcenter' }">新闻中心</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <div style="line-height: 0.35rem;text-align: right;width: 50%;">
          <span style="margin:0 10px" @click="clickone" :class=" step === 1 ? 'active' : 'noactive'">企业新闻</span>
          <span style="margin:0 10px" @click="clicktwo" :class=" step === 2 ? 'active' : 'noactive'">行业资讯</span>
          <span style="margin:0 10px" @click="clickthree" :class=" step === 3 ? 'active' : 'noactive'">媒体报道</span>
        </div> -->
      </div>
      <div class="bussBox">
        <div style="width: 60%; margin: 0 auto">
          <!-- <div style="display: flex"> -->
          <div style="width: 100%">
            <div style="display: flex;background-color: rgb(24, 28, 47);; padding: 15px;cursor: pointer;"
              v-for="(item, index) in pcprogrem" :key="index" @click="godetail(item)">
              <div>
                <img v-if="item.showImage && item.showImage != ''" :src="item.showImage"
                  style="width: 200px;height: 135px;cursor: pointer;" />
                <img v-else src="@/assets/images/acquiesce.png" style="width: 200px;height: 135px;cursor: pointer;" />
              </div>
              <div style="padding: 0px 25px;">
                <div style="font-size: 18px; color: rgb(253, 244, 230);">
                  {{ item.title }}
                </div>
                <div style="
                          font-size: 14px;
                          color: rgb(253, 244, 230);;
                          height: 45px;
                          margin-top: 10px;
                          overflow: hidden;
                        " class="messagedetail content_div" v-html="item.content">
                  <!-- 石家庄市鹿鹿镇镇以建设鹿泉区获鹿镇镇以建设鹿泉区获鹿镇镇以建
                    设鹿泉区获鹿镇镇以建设鹿泉区获... -->
                </div>
                <div style="margin-top: 0.2rem">
                  <span style="color: rgb(253, 244, 230);;">发布时间:{{ item.created }}</span>
                </div>
              </div>
            </div>
            <div style="margin: 20px auto;">
              <div style="
                        margin: auto;
                        width: 55%;
                        display: flex;
                        justify-content: space-around;
                      ">
                <div>
                  <!-- <el-button
                :disabled="currentPage === 1"
                class="first-pager"
                @click="toFirstPage"
                >首页</el-button
              > -->
                </div>
                <el-pagination ref="pagination" background layout="prev, pager, next" :current-page="currentPage"
                  :page-count="totalpage" @current-change="changecurrent" next-text="下一页" prev-text="上一页" :small="true">
                </el-pagination>
                <div style="height: 51px">
                  <!-- <el-button class="last-pager" @click="toLastPage">末页</el-button> -->
                </div>
              </div>
            </div>
            <!-- </div> -->
            <!-- <div style="width: 30%; margin-left: 25px; text-align: left">
              <div>
                <div style="
                      width: 3px;
                      height: 13px;
                      background-color: #e6b150;
                      display: inline-block;
                      margin-right: 5px;
                    "></div>
                热点新闻
              </div> -->
            <!-- <div
                style="background-color: #fff; margin: 10px 0"
                v-for="(item, index) in 2"
                :key="index"
              >
              <a style="color:#333333">
                <img
                  src="../../assets/pc新闻中心/图层 8.png"
                  style="
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    vertical-align: Baseline;
                  "
                />
                <div
                  style="
                    width: 3px;
                    height: 13px;
                    background-color: #fff;
                    display: inline-block;
                  "
                ></div>
                各个房间啊高房价卡给发几款
              </a>
              </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div id="mobiel" style="height: 110%">
      <div class="mobielhead">
        <div style="line-height: 2rem">
          <img src="../../assets/pc新闻中心/022018010203_0app.png" alt="" style="width: 3rem" />
        </div>
      </div>
      <div class="mobiel-contain" style="overflow: auto">
        <div class="mobiel-conmessage" v-for="(item, index) in moprogrem" :key="index" @click="godetail(item)"
          style="display: flex">
          <div>
            <img v-if="item.showImage && item.showImage != ''" :src="item.showImage"
              style="width: 150px; height: 150px" />
            <img v-else src="@/assets/images/acquiesce.png" style="width: 150px; height: 150px" />
          </div>
          <div style="width: 70%; overflow: hidden; margin-left: 20px">
            <div :style="{ color: item.istrue == true ? '#E6B150' : '#000000' }"
              style="font-size: 20px; font-weight: 450">
              {{ item.title }}
            </div>
            <div style="width: 99%; margin-top: 15px; font-size: 14px" class="messagedetail">
              <div>
                {{ item.highlightContent }}
              </div>
            </div>
            <div style="margin-top: 20px; font-size: 16px">
              发布时间:{{ item.created }}
            </div>
          </div>
        </div>
        <div style="text-align: center" @click="getmore" v-if="mototal < mototals">
          更多
        </div>
        <div style="width: 100%; height: 100px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { postAction } from "@/api/manage";
import { MessageBox } from "element-ui";
import menutable from "@/components/menu/menutable";
export default {
  components: { menutable },
  // props: ["pcprogrem"],
  data() {
    return {
      query: [],
      contain: {},
      step: 0,
      mototal: 0,
      mototals: null,
      currentPage: 1,
      mocurrentPage: 1,
      pageSize: 4,
      pcprogrem: [],
      totalpage: null,
      moprogrem: [],
    };
  },
  methods: {
    getlist() {
      postAction(
        "/api/article/paginate",
        {
          orderBy:'created desc',
          categoryId: 3,
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        console.log(res, '后台返回新闻')
        this.pcprogrem = res.page.list;
        this.totalpage = res.page.totalPage;
      });
    },
    getmobiellist() {
      postAction(
        "/api/article/paginate",
        {
          categoryId: 3,
          pageNumber: this.mocurrentPage,
          pageSize: this.pageSize,
        },
        { "Content-Type": "application/x-www-form-urlencoded" }
      ).then((res) => {
        // console.log(res.page.list.length,'changdu')
        this.mototal += res.page.list.length;
        this.mototals = res.page.totalRow;
        // console.log(res,'新闻45454545返回')
        res.page.list.forEach((item) => {
          this.moprogrem.push(item);
        });

        //this.totalpage =res.page.totalPage
      });
    },
    changecurrent(val) {
      this.currentPage = val;
      this.getlist();
    },
    clickone() {
      this.step = 1;
      // console.log(this.step,'11')
    },
    clicktwo() {
      this.step = 2;
      // console.log(this.step,'22')
    },
    getmore() {
      this.mocurrentPage++;
      this.getmobiellist();
    },
    godetail(val) {
      window.open(`/newcenterdetail?id=${val.id}&slug=${val.siteId}`);
      // this.$router.push({
      //   path: "/newcenterdetail",
      //   query: {
      //     id: val.id,
      //     slug: val.siteId,
      //   },
      // });
    },
    clickthree() {
      this.step = 3;
    },
  },
  filters: {},
  mounted() {
    this.getlist();
    this.getmobiellist();
    // console.log(this.pcprogrem,'fuchuan')
    // this.listData();
    // this.initYearList();
  },
};
</script>
<style lang="less" scoped>
#pc {
  display: none;
}

#mobiel {
  display: none;
}

@media screen and (max-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: none;
  }

  #mobiel {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  //     body {
  //    background-color: #111421 !important;
  //    background-image: none;
  // }

  #pc {
    display: block;
  }

  #mobiel {
    display: none;
  }
}

.messagedetail {
  display: -webkit-box !important;
  overflow: hidden;

  word-break: break-all;
  text-overflow: ellipsis;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.active {
  color: #000;
  font-weight: 800;
}

.noactive {
  color: #666666;
  font-weight: 100;
}

/deep/ .el-pagination--small span:not([class*="suffix"]) {
  font-size: 5px;
  font-weight: 300;
  background-color: rgb(24, 29, 47);
  color: rgb(211, 188, 147);
}

.content_div /deep/p {
  background-color: transparent !important;
  color: white !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
  font-size: unset !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(211, 188, 147);
  color: white;
}

/deep/ .el-pagination.is-background.el-pagination--small .el-pager li {
  margin: 0 0.04rem;
  min-width: 0.29333rem;
  font-size: 7px;
  background-color: rgb(24, 29, 47);
  color: rgb(211, 188, 147);
}

.bussBox {
  width: 100%;
  padding-top: 15px;
  height: 5.6rem;
  background-image: url('~@/assets/images/back.png');
  background-position: 50% center;
  border-top: 0.01333rem solid rgb(211, 188, 147);
}

.el-pagination {
  text-align: center;
  font-weight: 300;
  display: inline-block;
}

/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #d9bb95;
}

/deep/.el-breadcrumb__inner.is-link {
  color: #d9bb95;
}

.el-breadcrumb {
  font-size: 0.05rem;
  line-height: 0.35rem;
  background-color: #fff;
  height: 0.35rem;
  padding-left: 2.8rem;
}

.pc-top {
  background-image: url(../../assets/招贤纳士/图层\ 14.png);
  width: 100%;
  height: 1.5rem;
  text-align: center;
}

.mobiel-contain {
  background-color: #f5f5f5;
  padding: 10px 0;

  .mobiel-conmessage {
    width: 95%;
    margin: 10px auto;
    height: auto;
    background-color: #fff;
    padding: 0.2rem;
  }
}

.mobielhead {
  background-image: url(../../assets/招贤纳士/图层\ 10.png);
  height: 2rem;
  text-align: center;
}
</style>