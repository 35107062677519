<template>
  <div>
    <div class="breadmenu">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item style="font-size: 0.12rem;" :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item style="font-size: 0.1rem;" :to="{ path: '/recruit'}">招贤纳士</el-breadcrumb-item>
        <!-- <el-breadcrumb-item :to="{ path: '/recruit/detail'}">职位详情</el-breadcrumb-item> -->
        <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
      </el-breadcrumb>
    </div>
  </div>
</template>
  <script>
import { getUserInfo, setUserInfo, getCurrentCompany } from "@/utils/storage";
import { postAction } from "@/api/manage";
export default {
  data() {
    return {
      userInfo: {
        setting: [],
      },
      bindData: "",
      bannerList: [
        {
          url: require("../../assets/website/shou1.jpg"),
          // url:"../../assets/website/shou1.jpg"
        },
        {
          url: require("../../assets/website/shou2.jpg"),
          // url:"../../assets/website/shou2.jpg"
        },
      ],
    };
  },
  created() {

  },
  activated() {},
  methods: {},
  mounted() {},
  destroyed() {},
};
</script>
  <style lang="less" scoped>
  .el-breadcrumb{
    font-size: 0.05rem;
    line-height: 0.35rem;
    background-color: #111421;
    height: 0.35rem;
    padding-left: 2.8rem;
  }
  .breadmenu{
    ::v-deep .el-breadcrumb__inner.is-link{
      color: #d9bb95;
    }
    ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner,{
      color: #d9bb95;
    }
  }
</style>